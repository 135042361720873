html,body{
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

h1,h2,h3,h4,h5,h6 {
  text-align: center;
  word-break: break-word;
}
